import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import loadable from '@loadable/component';
//styles
import styles from './ddt.module.css';
//components
import Bullets from 'components/bullets';
import GetConsultation from 'components/free-consultation';
import Booking from 'components/feedback';
import ImageBullets from 'components/image-bullets';
import Layout from '../components/layout';
import tech from './tech';
import Tabs from 'components/tabs';
import Recognition from '../../recognition';
import Blockquote from '../components/blockquote';
import { Heypractice, Ifit, Adact, Condogenie, Backbone } from 'components/carousel';
import Technology, { TechnologyGroup } from 'components/technology';
//meta
import identificators from 'components/googleAnalyticsIdentificators';
//data
import { meta } from '../../../metaData';
import chanlangesList from './we-undertake-to-meet-your-chalange.json';
// hooks
import { useFaqData } from 'hooks/useFaqData';

const LIST = [
  {
    title: 'INTRO call',
  },
  {
    title: 'Discovery phase',
  },
  {
    title: 'Design phase',
  },
  {
    title: 'Development phase',
  },
  {
    title: 'Product launch',
  },
  {
    title: 'support',
  },
];

interface Props {
  location: Location;
}

const SwiperSlider = loadable(() => import('components/swiper-slider'));
const ReviewSlider = loadable(() => import('components/swiper-slider/review-slider'));

function Main({ location }: Props) {
  const faqData = useFaqData('allStrapiService', 'Dedicated team');
  const data = useStaticQuery(graphql`
    query {
      davidNeuendorf: file(relativePath: { eq: "david-neuendorf.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heyPractice: file(relativePath: { eq: "hey-practice.svg" }) {
        publicURL
      }

      theoMendez: file(relativePath: { eq: "theo-mendez.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      snafuLogo: file(relativePath: { eq: "snafu-logo.svg" }) {
        publicURL
      }
      michelleBowser: file(relativePath: { eq: "michelle-bowser.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ifitLogo: file(relativePath: { eq: "ifit-logo.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <Layout
      title="Dedicated Team"
      subtitle="Driven by a streamlined remote working process, our seasoned experts will seamlessly expand your on-site team or bridge your skill gaps while embracing your mindset and technology you use."
      metaData={{ main: meta.dedicatedTeam, faq: faqData?.list }}
      pathname={location.pathname}
    >
      <section className="section">
        <div className="inner">
          <h2 className={clsx(styles.title, styles.titleMargin)}>
            We undertake to meet your challenges
          </h2>
          <p className="subtitle">
            Stay focused on your business goals while we take care of the rest.
          </p>
          <Bullets list={chanlangesList} className={styles.bullets} />
        </div>
      </section>
      <section className={styles.carousel}>
        <SwiperSlider>
          <Adact />
          <Heypractice />
          <Ifit />
          <Backbone />
          <Condogenie />
        </SwiperSlider>
      </section>
      <section className="section">
        <div className="inner">
          <h2 className="title">Our core expertise</h2>
          <p className="subtitle">
            We dovetail our extensive software devlopment experience with up-to-date innovative
            technologies to put your revolutionary ideas in motion.
          </p>
          <ImageBullets list={tech} className={styles.tech} />
        </div>
      </section>
      <section className="section">
        <ReviewSlider swiperClassName={styles.blockquoteSwiper}>
          <Blockquote
            text="“With Brocoders, we were able to set up a highly skilled IT team in the shortest amount of time. For finding a team with similar skills we would have needed 4-6 months instead of the instant start.”"
            img={data.davidNeuendorf.childImageSharp.fluid}
            logoImg={data.heyPractice.publicURL}
            isLogo={true}
            logoStyles={styles.HeyPracticeLogo}
            isRating
            withTitle
            author={
              <React.Fragment>
                <strong>David Neuendorf </strong> <br />
                CEO,{' '}
                <a
                  href="https://www.heypractice.com/de/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.BqLink}
                >
                  HeyPractice.com
                </a>
              </React.Fragment>
            }
          />

          <Blockquote
            text="“Having a team composed and ready for us that already knew how to work together set them apart for me. They worked very closely together with our team and were involved in our planning processes.”"
            img={data.michelleBowser.childImageSharp.fluid}
            logoImg={data.ifitLogo.publicURL}
            isLogo={true}
            withTitle
            isRating
            author={
              <React.Fragment>
                <strong>Michelle Bowser </strong> <br />
                Director of Technical Operations,{' '}
                <a
                  href="https://www.ifit.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  // className={styles.BqLink}
                >
                  iFit
                </a>
              </React.Fragment>
            }
          />
          <Blockquote
            text="“Brocoders was on top of their project management game — they were one of the most productive and responsive teams we ever worked with.”"
            img={data.theoMendez.childImageSharp.fluid}
            logoImg={data.snafuLogo.publicURL}
            isLogo={true}
            withTitle
            isRating
            author={
              <React.Fragment>
                <strong>Theo Mendez </strong> <br />
                Product Manager,{' '}
                <a
                  href="https://www.snafurecords.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.BqLink}
                >
                  SNAFU Records
                </a>
              </React.Fragment>
            }
          />
        </ReviewSlider>
      </section>
      <section className={styles.sectionGrey}></section>
      <GetConsultation
        title="Make the best out of Brocoders team"
        text="Delivering dedicated services in a swift and efficient manner"
        linkText="Hire us"
        linkId={identificators.HIRE_US_BANNER}
      />
      <section className="section">
        <div className="inner">
          <h2 className="title">Work process</h2>
          <Tabs list={LIST} btnClassName="tabs__btn_30" isArrow>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  Here at Brocoders, we work shoulder-to-shoulder with you in an open manner
                  throughout the entire product life-cycle. To cover all details and ensure the
                  completeness of the requirements elicitation, we start from a first call and
                  meetings with a customer.
                </p>
                <p>
                  We share our expertise and provide you with the CV’s of our team members, so you
                  can make sure that we perfectly fit your requirements and needs in the first
                  place.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>1 hour</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      NDA
                      <br />
                      Consultation
                    </p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Account Manager
                      <br />
                      Stakeholder
                      <br />
                      Technical consultant
                    </p>
                  </li>
                  <li>
                    <h4>Apps</h4>
                    <TechnologyGroup technology={['skype', 'zoom', 'googleMeet']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  Our task at this stage is to study in detail the business task that you want to
                  solve with the help of the developed solution. Close cooperation with stakeholders
                  studying the business of the client, analysis of the market and competitors - all
                  this is necessary to draw up the most detailed document with the requirements and
                  render the low fidelity prototype.
                </p>
                <p>
                  This is a very important stage for business owners as well, since you can create a
                  clear vision and focus only on business-valuable solutions.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>Up to 3 days</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      Project Proposal
                      <br />
                      Contract for Prototype service
                    </p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Account manager
                      <br />
                      Business analyst
                      <br />
                      Stakeholders
                    </p>
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <h3>Low-fidelity prototype</h3>
                <p>
                  First of all, we establish the iterative development model and the lean startup
                  methodology to prepare the wireframes along with a low-fidelity prototype and
                  perform a feature-based estimation.
                </p>
                <h3>Feature-based estimation</h3>
                <p>
                  It’s crucial to evaluate the entire project and provide a customer with a clear
                  vision of how much time and money the development process might take. That said,
                  we use a feature decomposition to split the functionality into small parts, which
                  can then be estimated by our technical experts.
                </p>
                <p>
                  Our customers have full access to all relevant data, so they can assess further
                  steps, as well as protect the budget to investors. Once it’s done, we adjust a
                  toolset for the development stage and sign up a contract along with NDA’s to
                  ensure the security and legal validity of our relationship.
                </p>
                <h3>UX/UI Design</h3>
                <p>
                  The UI/UX stage is as vital as any other development steps, thus providing the
                  end-users with eye-pleasing design and an easy-to-use interface is one of our top
                  priorities.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>1 month</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      Low-fidelity prototype in Figma
                      <br />
                      Feature-based estimation
                      <br />
                      Feature-decomposition
                      <br />
                      Graphic Project in Figma
                    </p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Business Analyst
                      <br />
                      Designer
                      <br />
                      Project Manager
                      <br />
                      Stakeholders
                      <br />
                      Technical consultant
                    </p>
                  </li>
                  <li>
                    <h4>Apps</h4>
                    <Technology list={['figma', 'notion']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <h3>Project setup</h3>
                <p>
                  It’s time to set up a development environment, define the project’s architecture
                  and make a project roadmap. To strengthen the overall performance and ensure
                  everything is working properly, we carry out functional, integration and usability
                  tests. We also integrate manual and auto QA testing at this stage.
                </p>
                <p>
                  At the end of each sprint, we provide the stakeholders with a demo to showcase the
                  workflow and the functionality we’ve already made. Hence, all the stakeholders
                  have a clear vision of the working process, so they can leave feedback and make
                  sure we are on the right path.
                </p>
                <h3>Project management</h3>
                <p>
                  We can either manage a project on your side or take care of every step and the
                  entire technological process ourselves, so you can pay maximum attention to your
                  strategic and business goals. Based on years of practice, we’ve come up with a
                  robust and well-established workflow, which proved to be highly effective and
                  client-oriented.
                </p>
                <h3>Transparent work process</h3>
                <p>
                  To ensure a project’s safety, we apply the latest security and agile-driven
                  practices, as well as provide transparent reports, according to a customer’s
                  request. Besides, you can come to our office any time to work with our team and
                  look at the whole working process yourself. Moreover, we can strengthen a project
                  by applying extra resources once you request to do so.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>Depends on project</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      Environment setup <br />
                      Staging and production servers <br />
                      Intermediate releases <br />
                      Weekly Reports
                    </p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Business Analyst <br />
                      Project Manager <br />
                      Stakeholders <br />
                      Development team <br />
                      QA team <br />
                      Devops <br />
                    </p>
                  </li>
                  <li>
                    <h4>Technology</h4>
                    <TechnologyGroup technology={['react', 'reactNative', 'nodejs', 'gatsby']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  We believe it’s better to double-check everything from scratch, so we go through
                  the pre-launch checklist to ensure the project is fully-functioning and ready for
                  deployment.
                </p>
                <p>
                  Then we can either upload your solution to the production server in case it’s a
                  web-driven app or submit it to online stores such as the AppStore or Google Play
                  in case it’s a mobile-based app.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>About a week</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>Final release</p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Business Analyst <br />
                      Designer <br />
                      Project Manager <br />
                      Devops <br />
                    </p>
                  </li>
                  <li>
                    <h4>Technology</h4>
                    <Technology list={['amazon']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  Due to the client-oriented approach, our company keeps an eye on your product
                  after the final release and helps you scale it once the total number of end-users
                  starts growing.
                </p>
                <p>
                  At Brocoders, we care deeply about our customers and the solutions we develop, so
                  we provide continuous maintenance and support to our clients, as well as assign a
                  dedicated developer to a project to fix any possible bugs and respond to
                  inquiries.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>About a week</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>Fixes and improvements</p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Account manager <br />
                      Project Manager <br />
                      Stakeholders <br />
                      Development team
                    </p>
                  </li>
                </ul>
              </div>
            </li>
          </Tabs>
        </div>
      </section>
      <section className={clsx('section', styles.sectionMargin)}>
        <div className="inner">
          <h2 className="title">Recognition</h2>
          <Recognition />
        </div>
      </section>
      <section className="inner">{/* <FAQ data={faqData} /> */}</section>
      <Booking />
    </Layout>
  );
}

export default Main;
